import { Flow } from "./FlowDTOs";

type Guid = string;
/**
 * A section refers to a block of options that are displayed on the landing page,
 * typically organised by which options are available. Eg, a section during out-
 * of-office hours could have a title 'Temporarily unavailable' and include Admin
 * Query and Medical Request options
 */
export type Section = {
    id: Guid;
    order: number;
    title: string;
    subtitle: string | null;
    categories: Record<Guid, Category>;
};

/**
 * A category contains an option on the landing page, which either includes an external
 * link (eg 'Online advice') or a series of flows for submitting in the internal
 * experience (eg 'Admin query' is a category with a series of flows)
 */
export type Category = QuestionsCategory | HyperlinkCategory | MarkdownCategory;

export type BaseCategory<T extends CategoryType = CategoryType> = {
    id: Guid;
    type: T;
    order: number;
    isEnabled: boolean;
    title: string;
    subtitle: string | null;
    hyperlink: null;
    flows: null;
    markdownPage: null;
};

export enum CategoryType {
    Questions = "Questions",
    Hyperlink = "Hyperlink",
    Markdown = "Markdown",
}

export type QuestionsCategory = Omit<
    BaseCategory<CategoryType.Questions>,
    "flows"
> & {
    flows: Flow[];
};

export type HyperlinkCategory = Omit<
    BaseCategory<CategoryType.Hyperlink>,
    "hyperlink"
> & {
    hyperlink: string;
};

export type MarkdownCategory = Omit<
    BaseCategory<CategoryType.Markdown>,
    "markdownPage"
> & {
    markdownPage: string;
};
