import { IncomingHttpHeaders } from "node:http";

import { patientInitiatedServerUrl, patientServerUrl } from "../settings";
import {
    AuthenticationResponse,
    AuthenticationState,
    BaseDocument,
    ConversationListItem,
    FetchAppointmentDetailsResponse,
    FetchConversationContextResponse,
    FetchConversationResponse,
    FetchConversations,
    FetchDocumentsForPatientResponse,
    FetchOrganisationDocumentsResponse,
    OrganisationDocument,
} from "./ApiDTOs";
import { Appointment, RudderstackAnalyticsData } from "./PatientServerDTOs";
import { v4 as uuid } from "uuid";
import { Helpers, Log } from "@accurx/shared";

export const getBackendBase = () => {
    return patientServerUrl() || "http://patient.default.svc.cluster.local";
};

export const getPatientInitiatedBackendBase = () => {
    return (
        patientInitiatedServerUrl() ||
        "http://patientinitiated.default.svc.cluster.local"
    );
};

export const getUrlWithBackendBase = (url: string): string =>
    `${getBackendBase()}${url}`;
export const getUrlWithPatientInitiatedBackendBase = (url: string): string =>
    `${getPatientInitiatedBackendBase()}${url}`;

export const apiGetResult = async <T extends any = any>(
    backendUrl: string,
    requestHeaders: IncomingHttpHeaders,
): Promise<{
    result: Response | null;
    jsonResult: T | null;
}> => {
    const cookies = requestHeaders.cookie;
    const prHeader = (
        requestHeaders["AccurxPrNumber"] ?? requestHeaders["accurxprnumber"]
    )?.toString();
    const accurxCorrelationId =
        (
            requestHeaders["AccurxCorrelationId"] ??
            requestHeaders["accurxcorrelationid"]
        )?.toString() ?? uuid();

    // WIP that can only be testsed in prod:
    // try catch block added to handle network failures as warnings with tags
    try {
        const result = await fetch(backendUrl, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Cookie: cookies ?? "",
                AccurxPrNumber: prHeader ?? "",
                AccurxCorrelationId: accurxCorrelationId,
            },
        });
        const success = result.ok;

        const jsonResult = success ? await result.json() : null;
        return {
            result,
            jsonResult,
        };
    } catch (error) {
        if (Helpers.isNetworkIssue(error)) {
            Log.warn("Failed to Fetch Server Side", {
                tags: { "api.errorType": "Network Issue" },
                originalException: error,
            });
        } else {
            Log.error("Unknown Server Side Error", {
                originalException: error,
            });
        }
        return {
            result: null,
            jsonResult: null,
        };
    }
};

/**
 * At the moment, does not error if the JSON received is correct JSON but not the right shape.
 * This could be achieved by writing type guards for each DTO but they would need to be maintained.
 * See https://stackoverflow.com/a/43895205 for context, TDLR: there's no casting in javascript, so you cannot throw if "casting fails".
 */

/**
 * This is for API calls that will run server-side (aka in the getServerSideProps function which runs in the server)
 * We need a specific helper for server-side calls as cookies aren't passed through by default
 */
class PatientPortalApiServer {
    static async fetchConversation(
        conversationId: string,
        requestHeaders: IncomingHttpHeaders,
    ): Promise<FetchConversationResponse> {
        const backendBase = getBackendBase();
        const backendUrl = `${backendBase}/api/patient/conversation/${encodeURIComponent(
            conversationId,
        )}`;

        const apiResult = await apiGetResult(backendUrl, requestHeaders);

        const { result, jsonResult } = apiResult;

        return jsonResult
            ? {
                  success: true,
                  conversation: jsonResult,
                  unauthorised: false,
              }
            : {
                  success: false,
                  conversation: null,
                  unauthorised: result?.status === 401,
              };
    }

    // Used on login screen before conversation is fetched
    static async fetchConversationContext(
        conversationId: string,
        requestHeaders: IncomingHttpHeaders,
    ): Promise<FetchConversationContextResponse> {
        const backendBase = getBackendBase();
        const backendUrl = `${backendBase}/api/patient/conversation/${encodeURIComponent(
            conversationId,
        )}/context`;

        const apiResult = await apiGetResult(backendUrl, requestHeaders);

        const { jsonResult } = apiResult;

        return jsonResult
            ? {
                  success: true,
                  workSpaceName: jsonResult.workspaceName,
                  isCancelled: jsonResult.isCancelled,
                  isLocked: jsonResult.isLocked,
                  isNhsLoginEnabled: jsonResult.isNhsLoginEnabled,
                  productOrigin: jsonResult.productOrigin,
                  isDownForMaintenance: jsonResult.isDownForMaintenance,
              }
            : {
                  success: false,
                  workSpaceName: null,
                  isCancelled: null,
                  isLocked: null,
                  isNhsLoginEnabled: false,
                  productOrigin: null,
                  isDownForMaintenance: false,
              };
    }

    // Used on both landing pages to determine if the patient is authenticated
    static async fetchAuthenticationContext(
        requestHeaders: IncomingHttpHeaders,
        conversationUrl?: string,
    ): Promise<AuthenticationResponse> {
        const backendBase = getBackendBase();
        const param =
            conversationUrl !== undefined
                ? `?conversationUrl=${conversationUrl}`
                : "";
        const backendUrl = `${backendBase}/api/patient/authenticationcontext${param}`;

        const apiResult = await apiGetResult<AuthenticationResponse>(
            backendUrl,
            requestHeaders,
        );

        const { jsonResult } = apiResult;

        return {
            authenticationState:
                jsonResult?.authenticationState ?? AuthenticationState.None,
            isDownForMaintenance: jsonResult?.isDownForMaintenance ?? false,
        };
    }

    static async fetchPatientDocuments(
        appointmentId: string,
        requestHeaders: IncomingHttpHeaders,
    ): Promise<FetchDocumentsForPatientResponse> {
        const backendBase = getBackendBase();
        const backendUrl = `${backendBase}/api/patient/appointmentresources/${encodeURIComponent(
            appointmentId,
        )}/documents`;

        const apiResult = await apiGetResult<{ documents: BaseDocument[] }>(
            backendUrl,
            requestHeaders,
        );

        const { result, jsonResult } = apiResult;

        //OpenIdConnect returns a redirect result - client will handle if we return false for all
        if (result?.redirected) {
            return { success: false, documents: [] };
        }

        return jsonResult
            ? {
                  success: true,
                  documents: jsonResult.documents,
              }
            : { success: false, documents: [] };
    }

    static async fetchAppointmentDetails(
        appointmentId: string,
        requestHeaders: IncomingHttpHeaders,
    ): Promise<FetchAppointmentDetailsResponse> {
        const backendBase = getBackendBase();
        const backendUrl = `${backendBase}/api/patient/appointmentdetails/${encodeURIComponent(
            appointmentId,
        )}`;

        const apiResult = await apiGetResult<Appointment>(
            backendUrl,
            requestHeaders,
        );

        const { result, jsonResult } = apiResult;

        //OpenIdConnect returns a redirect result - client will handle if we return false for all
        if (result?.redirected) {
            return { success: false, appointment: null };
        }

        return jsonResult
            ? { success: true, appointment: jsonResult }
            : { success: false, appointment: null };
    }

    static async fetchOrganisationResources(
        appointmentId: string,
        requestHeaders: IncomingHttpHeaders,
    ): Promise<FetchOrganisationDocumentsResponse> {
        const backendBase = getBackendBase();

        const backendUrl = `${backendBase}/api/patient/appointmentresources/${encodeURIComponent(
            appointmentId,
        )}/resources`;

        const apiResult = await apiGetResult<{
            documents: OrganisationDocument[];
        }>(backendUrl, requestHeaders);

        const { result, jsonResult } = apiResult;

        //OpenIdConnect returns a redirect result - client will handle if we return false for all
        if (result?.redirected) {
            return { success: false, documents: [] };
        }

        return jsonResult
            ? {
                  success: true,
                  documents: jsonResult.documents,
              }
            : { success: false, documents: [] };
    }

    static async fetchConversationList(
        requestHeaders: IncomingHttpHeaders,
    ): Promise<FetchConversations> {
        const backendBase = getBackendBase();
        const backendUrl = `${backendBase}/api/patient/conversations`;

        const apiResult = await apiGetResult<{
            conversations: ConversationListItem[];
        }>(backendUrl, requestHeaders);

        const { result, jsonResult } = apiResult;

        return jsonResult
            ? {
                  success: true,
                  conversations: jsonResult.conversations,
                  unauthorised: false,
              }
            : {
                  success: false,
                  conversations: [],
                  unauthorised: result?.status === 401,
              };
    }

    static async fetchRudderstackAnalyticsData(
        requestHeaders: IncomingHttpHeaders,
        conversationUrl?: string,
    ): Promise<RudderstackAnalyticsData> {
        const backendBase = getBackendBase();
        const param =
            conversationUrl !== undefined
                ? `?conversationUrl=${conversationUrl}`
                : "";
        const backendUrl = `${backendBase}/api/patient/EventDetails${param}`;

        const apiResult = await apiGetResult(backendUrl, requestHeaders);

        const { jsonResult } = apiResult;

        return jsonResult;
    }
}

export default PatientPortalApiServer;
