import { Section } from "./SectionsDTOs";
import { IncomingHttpHeaders } from "node:http";
import { getApiEndpoint } from "@accurx/shared";
import {
    apiGetResult,
    getUrlWithPatientInitiatedBackendBase,
} from "../../../../api/PatientPortalApiServer";

const ENDPOINTS = {
    ORGANISATION_FORMS: "patientinitiated/:organisationCode/forms",
} as const;

export const fetchSections = async (args: {
    organisationCode: string;
    token: string;
    requestHeaders: IncomingHttpHeaders;
}): Promise<{
    sections: Section[];
} | null> => {
    const { organisationCode, token, requestHeaders } = args;

    const { urlFinal } = getApiEndpoint({
        path: ENDPOINTS.ORGANISATION_FORMS,
        params: { organisationCode },
        query: `?receptionFlowToken=${token}`,
    });

    const { jsonResult } = await apiGetResult<{ sections: Section[] }>(
        getUrlWithPatientInitiatedBackendBase(urlFinal),
        requestHeaders,
    );

    if (!jsonResult) return null;
    return { sections: jsonResult.sections };
};
